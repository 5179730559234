<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="safetyTopic_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-safetyTopic-filter></app-safetyTopic-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="safetyTopics"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import SafetyTopicFilter from '../../components/filter/FilterSafetyTopic'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'SafetyTopicListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('safetyTopic.list.id'),
          title: this.$t('safetyTopic.list.title'),
          code: this.$t('safetyTopic.list.code')
        },
        actions: {
          edit: 'safetyTopic_edit'
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    safetyTopics () {
      return this.$store.getters['safetyTopic/list']
    },
    totalCount () {
      return this.$store.getters['safetyTopic/totalCount']
    },
    page () {
      return this.$store.getters['safetyTopic/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appSafetyTopicFilter: SafetyTopicFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getSafetyTopics () {
      this.$store.dispatch('safetyTopic/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('safetyTopic/setPage', page)
      this.getSafetyTopics()
    }
  },
  mounted () {
    this.getSafetyTopics()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
